export const experiences = [
    {
        value: 30,
        slug: 'Packages Sold'
    },
    {
        value: 2,
        slug: 'Countries'
    },
    {
        value: 105,
        slug: 'Happy Clients'
    },
    {
        value: 2,
        slug: 'Years of Experience'
    }
]